<template>
  <div class="step-confirmation">
    <div class="view-section order-information">
      <span>{{ $t("checkout_order_paid_title", { orderId }) }}</span>
    </div>
    <div class="view-section assign-tickets">
      <div class="content-wrap">
        <img src="@/assets/checkout_tickets_icon.svg" alt="ticket" />
        <div>{{ $t("purchase_confirmaton_text") }}</div>
        <mem-button @click="openEventView">{{
          $t("purchase_confirmaton_assign")
        }}</mem-button>
      </div>
    </div>
    <div class="view-section eu-shirts" v-if="showShirtsSection">
      <div class="content-wrap">
        <!-- src="@/assets/t-shirts.png" -->
        <img
          src="https://images.ctfassets.net/1bk7n9wtl2gh/Mj4waEyvVrkd6Iep4bh2L/08b7197217dcdcc1a8e5b5f40ba013f8/Registration_picture_T4__1_.jpg"
          alt="Shirts image"
          loading="lazy"
        />
        <div class="text-title">{{ $t("checkout_promo_t_shirt_title") }}</div>
        <div class="text-description">
          {{ $t("checkout_promo_t_shirt_description") }}
        </div>
        <mem-button @click="openShirtPromo">{{
          $t("view_product")
        }}</mem-button>
      </div>
    </div>

    <div class="view-section order-receipt" v-if="!hideOrderDetails">
      <v-expansion-panels
        v-model="panel"
        active-class="mem-expansion-panels"
        light
        accordion
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="pannel-header">
              {{ $t("order_confirmation_view_full_receipt") }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <price-decomposition show-total :show-discount="showDiscount" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="view-section promo-block" v-if="showPromoBlock">
      <div class="content-wrap">
        <div class="promo-title">{{ $t("ice_cream_social_title") }}</div>
        <div class="promo-description">
          {{ $t("ice_cream_social_description") }}
        </div>
        <mem-button
          id="checkout-ics-button"
          class="ics-get-link-button"
          btn-type="secondary-dark"
          >{{ $t("ice_cream_social_button_description") }}</mem-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "lodash";
import { mapGetters, mapActions, mapMutations } from "vuex";
import deviceInfoMixin from "@/views/Auth/deviceInfoMixin";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "price-decomposition": () => import("./PriceDecomposition.vue"),
  },
  data: () => ({
    panel: [0],
  }),
  props: {
    showDiscount: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(["getOrderDescribe"]),
    ...mapMutations(["resetCheckoutState"]),
    openEventView() {
      this.$emit("gtm-push", "Assignment");
      localStorage.removeItem("orderId");

      if (this.checkoutRegion !== "US") {
        this.$router.push({ name: "events" });
        this.resetCheckoutState();
        return;
      }

      if (this.isMobile)
        return window.open("https://spartanrace.app.link/wVypCP1oyIb", "_self");
      return window.open("https://race.spartan.com/en/app/", "_self");

      // this.$emit("gtm-push", "Assignment");
      // this.$router.push({ name: "events" });
      // this.resetCheckoutState();
      // localStorage.removeItem("orderId");
    },
    openShirtPromo() {
      let windowReference = window.open();
      windowReference.location = `https://spartanraceeurope.shop/collections/training-for-collection-2025`;
    },
  },
  mixins: [deviceInfoMixin],
  computed: {
    ...mapGetters(["orderDetails", "checkoutRegion"]),
    orderId() {
      return localStorage.getItem("orderId");
    },
    hideOrderDetails() {
      return isEmpty(this.orderDetails);
    },
    showPromoBlock() {
      return false;
      // const countries = ["US"];
      // return countries.includes(this.checkoutRegion);
    },
    showShirtsSection() {
      let supportedRegions = ["UK", "EU"];
      return supportedRegions.includes(this.checkoutRegion);
    },
  },
  async mounted() {
    if (!this.hideOrderDetails) return;

    try {
      await this.getOrderDescribe();
    } catch (error) {
      console.log("Get order details error: ", error);
    }
  },
};
</script>
<style lang="scss" scoped>
.step-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  .view-section {
    background-color: #ffffff;
    max-width: 528px;
    width: 100%;
    margin-bottom: 8px;
    // margin: 8px auto 0 auto;
  }
  .order-information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin-top: 8px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    @media screen and (max-width: $mobile) {
      margin-top: 0;
      font-size: 16px;
      line-height: 21px;
    }
  }
  .assign-tickets {
    padding-top: 24px;
    padding-bottom: 34px;
    @media screen and (max-width: $mobile) {
      padding-top: 22px;
      padding-bottom: 24px;
    }
    .content-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 288px;
      margin: 0 auto;

      font-size: 22px;
      font-weight: 700;
      line-height: 27px;
      text-transform: capitalize;
      text-align: center;
      @media screen and (max-width: $mobile) {
        max-width: 230px;
        font-size: 18px;
        line-height: 22px;
      }
      img {
        margin-bottom: 14px;
        @media screen and (max-width: $mobile) {
          height: 48px;
          width: 48px;
          margin-bottom: 10px;
        }
      }
      button {
        margin-top: 14px;
        height: 44px;
        min-width: 202px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.04em;
        @media screen and (max-width: $mobile) {
          margin-top: 24px;
          min-width: 198px;
        }
      }
    }
  }
  .order-receipt {
    min-height: 65px;
    @media screen and (max-width: $mobile) {
      min-height: 56px;
    }
    .v-expansion-panel {
      &::before {
        box-shadow: none;
      }
      .v-expansion-panel-header {
        .v-expansion-panel-header__icon {
          color: #000000;
        }
      }
    }
    .pannel-header {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      padding-left: 24px;
      text-align: center;
      @media screen and (max-width: $mobile) {
        font-size: 16px;
      }
    }
  }
  .promo-block {
    background-image: url("https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/ics_checkout.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 282px;
    padding-bottom: 26px;
    @media screen and (max-width: $mobile) {
      height: 264px;
    }
    .content-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 324px;
      margin: 0 auto;
      text-align: center;
      color: #ffffff;
      @media screen and (max-width: $mobile) {
        max-width: 242px;
      }
      .promo-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        @media screen and (max-width: $mobile) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .promo-description {
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
        margin-bottom: 24px;
        @media screen and (max-width: $mobile) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      button {
        height: 44px;
        min-width: 202px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.04em;
        @media screen and (max-width: $mobile) {
          min-width: 150px;
        }
      }
    }
  }
  .eu-shirts {
    padding-top: 24px;
    padding-bottom: 44px;
    .content-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 288px;
      margin: 0 auto;
      .text-title {
        color: #222;
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.25px;
        text-transform: capitalize;
        margin-bottom: 14px;
      }
      .text-description {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: 0.04px;
        margin-bottom: 14px;
      }
      img {
        width: 248px;
        margin-bottom: 14px;
      }
    }
  }
}
</style>