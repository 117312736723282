export default [
  // TODO: Move "ZDServiceKey" from languages list to country object
  // Africa
  {
    region: "Africa",
    countries: [
      // Mauritius
      {
        country: "Mauritius",
        code: "MU",
        platform: "S3",
        languages: [
          {
            language: "français",
            code: "fr",
            homeUrl: "https://mu.spartan.com/fr",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "e3a78469-d9ed-4f2a-abb4-849415dbfaab",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://mu.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "e3a78469-d9ed-4f2a-abb4-849415dbfaab",
          },
        ],
      },
      // South Africa
      {
        country: "South Africa",
        code: "ZA",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://www.spartanrace.co.za/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "6da4ef0b-7cd5-4a3b-8c1f-4859684a271d",
          },
        ],
      },
    ],
  },
  // Americas
  {
    region: "Americas",
    countries: [
      // Brazil
      {
        country: "Brazil",
        code: "BR",
        platform: "S1",
        languages: [
          {
            language: "português",
            code: "pt",
            homeUrl: "https://www.spartanracebrasil.com.br/pt",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "088c18bd-71e0-41d7-bf1c-c2df4959cd0b",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://www.spartanracebrasil.com.br/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "088c18bd-71e0-41d7-bf1c-c2df4959cd0b",
          },
        ],
      },
      // Canada
      {
        country: "Canada",
        code: "CA",
        platform: "S3",
        languages: [
          {
            language: "français",
            code: "fr",
            homeUrl: "https://ca.spartan.com/fr",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "efe5e8d6-01ef-4496-b582-4c62ad896be9",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://ca.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "efe5e8d6-01ef-4496-b582-4c62ad896be9",
          },
        ],
      },
      // Chile
      {
        country: "Chile",
        code: "CL",
        platform: "S3",
        languages: [
          {
            language: "español",
            code: "es",
            homeUrl: "https://cl.spartan.com/es",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "a72bd3d4-c0a8-4c4e-af9d-46704091c932",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://cl.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "a72bd3d4-c0a8-4c4e-af9d-46704091c932",
          },
        ],
      },
      // Costa Rica
      {
        country: "Costa Rica",
        code: "CR",
        platform: "S3",
        languages: [
          {
            language: "Español",
            code: "es",
            homeUrl: "https://cr.spartan.com/es",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "a5c4f7b9-3332-4375-9a46-98dad1dd46f1",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://cr.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "a5c4f7b9-3332-4375-9a46-98dad1dd46f1",
          },
        ],
      },
      // Peru
      {
        country: "Peru",
        code: "PE", // CL
        platform: "S3",
        languages: [
          {
            language: "español",
            code: "es",
            homeUrl: "https://cl.spartan.com/es",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://cl.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
          },
        ],
      },
      // Mexico
      {
        country: "Mexico",
        code: "MX",
        platform: "S3",
        languages: [
          {
            language: "español",
            code: "es",
            homeUrl: "https://mx.spartan.com/es",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "f4d47766-c962-4737-94f8-78dc0c3803ee",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://mx.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "f4d47766-c962-4737-94f8-78dc0c3803ee",
          },
        ],
      },
      // United States
      {
        country: "United States",
        code: "US",
        platform: "S3",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://race.spartan.com/en",
            findRaceUrl: "https://race.spartan.com/en/race/find-race",
            leaderboardUrl:
              "https://race.spartan.com/en/race/past-results/[raceID]", // ??
            ZDServiceKey: "631dede2-6505-4fe2-ad1d-d8d5456eb6c2",
          },
        ],
      },
    ],
  },
  // Asia Pacific
  {
    region: "Asia Pacific",
    countries: [
      // Australia
      {
        country: "Australia",
        code: "AU",
        platform: "S3",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://au.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "99f797de-29cb-417c-b714-88aaafd76120",
          },
        ],
      },
      // China
      {
        country: "China",
        code: "CN",
        platform: "S3",
        languages: [
          {
            language: "chinese - 中文",
            code: "zh",
            homeUrl: "http://cn.spartan.com/zh",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "8f9494f4-e4b9-42dc-98b1-6e119a539419",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "http://cn.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "8f9494f4-e4b9-42dc-98b1-6e119a539419",
          },
        ],
      },
      // Hong Kong
      {
        country: "Hong Kong",
        code: "HK",
        platform: "S1",
        languages: [
          {
            language: "chinese - 中文",
            code: "zh",
            homeUrl: "https://www.spartanrace.hk/zh",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "912341e2-aaf7-4128-a90a-05f60afab9a5",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://www.spartanrace.hk/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "912341e2-aaf7-4128-a90a-05f60afab9a5",
          },
        ],
      },
      // Indonesia
      {
        country: "Indonesia",
        code: "ID",
        platform: "S3",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://id.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
          },
        ],
      },
      // Japan
      {
        country: "Japan",
        code: "JP",
        platform: "S3",
        languages: [
          {
            language: "japanese - 日本語",
            code: "ja",
            homeUrl: "https://jp.spartan.com/ja",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "c65f40f1-b456-4305-8a3e-76297688628a",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://jp.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "c65f40f1-b456-4305-8a3e-76297688628a",
          },
        ],
      },
      // Malaysia
      {
        country: "Malaysia",
        code: "MY",
        platform: "S3",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://mu.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "d01fb1ab-34f1-452a-85ca-8341979b8eb0",
          },
        ],
      },
      // New Zealand
      {
        country: "New Zealand",
        code: "NZ",
        platform: "S3",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://au.spartan.com/en",
            findRaceUrl: "/race/find-race?cty=new%20zealand",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "99f797de-29cb-417c-b714-88aaafd76120",
          },
        ],
      },
      // Philippines
      {
        country: "Philippines",
        code: "PH",
        platform: "S3",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://ph.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "934d1ed0-0759-44e4-91a9-1e8600df013d",
          },
        ],
      },
      // Singapore
      {
        country: "Singapore",
        code: "SG",
        platform: "S3",
        languages: [
          {
            language: "chinese - 中文",
            code: "zh",
            homeUrl: "http://sg.spartan.com/zh",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "7543ac33-7a7e-4403-b481-fd79e5bce275",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://sg.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "7543ac33-7a7e-4403-b481-fd79e5bce275",
          },
        ],
      },
      // South Korea
      {
        country: "South Korea",
        code: "KR",
        platform: "S3",
        languages: [
          {
            language: "korean - 한국어",
            code: "ko",
            homeUrl: "https://kr.spartan.com/ko",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "e85fdd72-f956-4501-b4f7-784e44ff0594",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://kr.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "e85fdd72-f956-4501-b4f7-784e44ff0594",
          },
        ],
      },
      // Taiwan
      {
        country: "Taiwan",
        code: "TW",
        platform: "S1",
        languages: [
          {
            language: "chinese - 中文",
            code: "zh",
            homeUrl: "https://www.spartanrace.tw/zh",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "ae31ea70-d1e8-40fa-b3a5-372abc742a10",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://www.spartanrace.tw/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "ae31ea70-d1e8-40fa-b3a5-372abc742a10",
          },
        ],
      },
      // Thailand
      {
        country: "Thailand",
        code: "TH",
        platform: "S1",
        languages: [
          {
            language: "chinese - 中文",
            code: "zh",
            homeUrl: "https://www.spartanrace.co.th/zh",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "5d393cc3-7779-4269-a6f8-1f40bac7db42",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://www.spartanrace.co.th/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "5d393cc3-7779-4269-a6f8-1f40bac7db42",
          },
        ],
      },
      // Vietnam
      {
        country: "Vietnam",
        code: "VN",
        platform: "S1",
        languages: [
          {
            language: "vietnamese - Tiếng Việt",
            code: "vi",
            homeUrl: "https://www.spartanrace.vn/vi",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "8a09800f-31dd-42cb-8f63-6b816added05",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://www.spartanrace.vn/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "8a09800f-31dd-42cb-8f63-6b816added05",
          },
        ],
      },
      // India
      {
        country: "India",
        code: "IN",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://in.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "ff225038-c836-4025-951a-5fcf5b744acc",
          },
        ],
      },
    ],
  },
  // Europe
  {
    region: "Europe",
    countries: [
      // Czech Republic
      {
        country: "Czech Republic",
        code: "CZ",
        platform: "S3",
        languages: [
          {
            language: "čeština",
            code: "cs",
            homeUrl: "https://cz.spartan.com/cs",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "2053167c-d292-4168-9a52-d9ccbe029316",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://cz.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "2053167c-d292-4168-9a52-d9ccbe029316",
          },
        ],
      },
      // Denmark
      {
        country: "Denmark",
        code: "DK",
        platform: "S3",
        languages: [
          {
            language: "dansk",
            code: "da",
            homeUrl: "https://dk.spartan.com/da",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "f709b221-5519-40b2-a975-09d4fa066af3",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://dk.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "f709b221-5519-40b2-a975-09d4fa066af3",
          },
        ],
      },
      // Finland
      {
        country: "Finland",
        code: "FI",
        platform: "S3",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://fi.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "0816c695-9491-4379-87f0-f4a9135586e2",
          },
        ],
      },
      // France
      {
        country: "France",
        code: "FR",
        platform: "S3",
        languages: [
          {
            language: "français",
            code: "fr",
            homeUrl: "https://fr.spartan.com/fr",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "b1fde403-b3e6-4885-85c4-f9276f355f90",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://fr.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "b1fde403-b3e6-4885-85c4-f9276f355f90",
          },
        ],
      },
      // Germany
      {
        country: "Germany",
        code: "DE",
        platform: "S3",
        languages: [
          {
            language: "deutsch",
            code: "de",
            homeUrl: "https://de.spartan.com/de",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "0340e825-bcd6-4602-85a7-dd681726edae",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://de.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "0340e825-bcd6-4602-85a7-dd681726edae",
          },
        ],
      },
      // Greece
      {
        country: "Greece",
        code: "GR",
        platform: "S3",
        languages: [
          {
            language: "greek - Ελληνικά",
            code: "el",
            homeUrl: "https://gr.spartan.com/el",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "4b5e7919-1f27-463a-b155-480fa5983c77",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://gr.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "4b5e7919-1f27-463a-b155-480fa5983c77",
          },
        ],
      },
      // Croatia
      {
        country: "Croatia",
        code: "HR",
        platform: "S3",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://hr.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "5e2ea5e7-096c-4d9b-b887-22c8d18f4fed",
          },
        ],
      },
      // Hungary
      {
        country: "Hungary",
        code: "HU",
        platform: "S3",
        languages: [
          {
            language: "hungarian",
            code: "hu",
            homeUrl: "https://hu.spartan.com/hu",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "35f82167-c7b7-42e8-96f5-ae3e9e9cb3c1",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://hu.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "35f82167-c7b7-42e8-96f5-ae3e9e9cb3c1",
          },
        ],
      },
      // Italy
      {
        country: "Italy",
        code: "IT",
        platform: "S3",
        languages: [
          {
            language: "italiano",
            code: "it",
            homeUrl: "https://it.spartan.com/it",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "295d839e-b3c9-418c-86a5-bcc1f70429ca",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://it.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "295d839e-b3c9-418c-86a5-bcc1f70429ca",
          },
        ],
      },
      // Netherlands
      {
        country: "Netherlands",
        code: "NL",
        platform: "S3",
        languages: [
          {
            language: "dutch",
            code: "nl",
            homeUrl: "https://nl.spartan.com/nl",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "0e3c4c5e-b8b7-44e3-83bd-2329d753b2bf",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://nl.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "0e3c4c5e-b8b7-44e3-83bd-2329d753b2bf",
          },
        ],
      },
      // Romania
      {
        country: "Romania",
        code: "RO",
        platform: "S3",
        languages: [
          {
            language: "română",
            code: "ro",
            homeUrl: "https://ro.spartan.com/ro",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "930a2a19-c194-4d5d-b811-49c41f1ecb51",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://ro.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "930a2a19-c194-4d5d-b811-49c41f1ecb51",
          },
        ],
      },
      // Slovakia
      {
        country: "Slovakia",
        code: "SK",
        platform: "S3",
        languages: [
          {
            language: "Slovák",
            code: "sk",
            homeUrl: "https://sk.spartan.com/sk",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "af0f957c-9db0-4781-8948-b87499a7c34b",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://sk.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "af0f957c-9db0-4781-8948-b87499a7c34b",
          },
        ],
      },
      // Spain
      {
        country: "Spain",
        code: "ES",
        platform: "S3",
        languages: [
          {
            language: "español",
            code: "es",
            homeUrl: "https://es.spartan.com/es",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "c216740b-b8ea-4674-bfb7-d02410484a95",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://es.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "c216740b-b8ea-4674-bfb7-d02410484a95",
          },
        ],
      },
      // Sweden !!!
      {
        country: "Sweden",
        code: "SE",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl:
              "https://race.spartan.com/en/championships/world-championships/sweden",
            findRaceUrl: "",
            leaderboardUrl: "/race/past-results/[raceID]",
          },
        ],
      },
      // Switzerland
      {
        country: "Switzerland",
        code: "CH",
        platform: "S3",
        languages: [
          {
            language: "français",
            code: "fr",
            homeUrl: "https://ch.spartan.com/fr",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "8c12203d-66e4-4703-8894-d07161fa5c3a",
          },
          {
            language: "deutsch",
            code: "de",
            homeUrl: "https://ch.spartan.com/de",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "8c12203d-66e4-4703-8894-d07161fa5c3a",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://ch.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "8c12203d-66e4-4703-8894-d07161fa5c3a",
          },
        ],
      },
      // United Kingdom
      {
        country: "United Kingdom",
        code: "GB",
        platform: "S3",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://uk.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "9924118d-94c7-4d0e-8565-1f211315d451",
          },
        ],
      },
      // Ukraine
      {
        country: "Ukraine",
        code: "UA",
        platform: "S1",
        languages: [
          {
            language: "ukrainian - українська",
            code: "uk",
            homeUrl: "https://www.spartan-race.com.ua/uk",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "00601ee6-103a-40ed-b58a-05c72075d966",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://www.spartan-race.com.ua/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "00601ee6-103a-40ed-b58a-05c72075d966",
          },
        ],
      },
      // Austria
      {
        country: "Austria",
        code: "AT",
        platform: "S3",
        languages: [
          {
            language: "deutsch",
            code: "de",
            homeUrl: "https://at.spartan.com/de",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "0340e825-bcd6-4602-85a7-dd681726edae",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://at.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "0340e825-bcd6-4602-85a7-dd681726edae",
          },
        ],
      },
      // Slovenia
      {
        country: "Slovenia",
        code: "SI",
        platform: "S3",
        languages: [
          {
            language: "Slovenščina",
            code: "sl",
            homeUrl: "https://si.spartan.com/sl",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "c78ce798-3b87-426c-997b-c5dc1d07e400",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://si.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "c78ce798-3b87-426c-997b-c5dc1d07e400",
          },
        ],
      },
      // Poland
      {
        country: "Poland",
        code: "PL",
        platform: "S3",
        languages: [
          {
            language: "Polski",
            code: "pl",
            homeUrl: "https://pl.spartan.com/pl",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "b1d5c0e4-2470-40ed-ae94-eb0615b2c1c8",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://pl.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "b1d5c0e4-2470-40ed-ae94-eb0615b2c1c8",
          },
        ],
      },
      // Andorra
      {
        country: "Andorra",
        code: "AD",
        platform: "S3",
        languages: [
          {
            language: "Español",
            code: "es",
            homeUrl: "https://es.spartan.com/es",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "c216740b-b8ea-4674-bfb7-d02410484a95",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://es.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "c216740b-b8ea-4674-bfb7-d02410484a95",
          },
        ],
      },
      // Malta
      {
        country: "Malta",
        code: "MT",
        platform: "S3",
        languages: [
          {
            language: "Italiano",
            code: "it",
            homeUrl: "https://it.spartan.com/it",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "295d839e-b3c9-418c-86a5-bcc1f70429ca",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://it.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "295d839e-b3c9-418c-86a5-bcc1f70429ca",
          },
        ],
      },
      // Liechtenstein
      {
        country: "Liechtenstein",
        code: "LI",
        platform: "S3",
        languages: [
          {
            language: "Français",
            code: "fr",
            homeUrl: "https://ch.spartan.com/fr",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
          },
          {
            language: "deutsch",
            code: "de",
            homeUrl: "https://ch.spartan.com/de",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
          },
          {
            language: "english",
            code: "en",
            homeUrl: "https://ch.spartan.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
          },
        ],
      },
    ],
  },
  // Middle East
  {
    region: "Middle East",
    countries: [
      // United Arab Emirates
      {
        country: "United Arab Emirates",
        code: "AE",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://spartanarabia.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "fa23e2af-11da-42c0-aa2a-cca626564285",
          },
          // {
          //   language: "arabic - عربى",
          //   code: "ar",
          // },
        ],
      },
      // Oman
      {
        country: "Oman",
        code: "OM",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://spartanarabia.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "fa23e2af-11da-42c0-aa2a-cca626564285",
          },
        ],
      },
      // Saudi Arabia
      {
        country: "Saudi Arabia",
        code: "SA",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://spartanarabia.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "fa23e2af-11da-42c0-aa2a-cca626564285",
          },
        ],
      },
      // Bahrain
      {
        country: "Bahrain",
        code: "BH",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://spartanarabia.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "fa23e2af-11da-42c0-aa2a-cca626564285",
          },
        ],
      },
      // Kuwait
      {
        country: "Kuwait",
        code: "KW",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://spartanarabia.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "fa23e2af-11da-42c0-aa2a-cca626564285",
          },
        ],
      },
      // Egypt
      {
        country: "Egypt",
        code: "EG",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://spartanarabia.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "fa23e2af-11da-42c0-aa2a-cca626564285",
          },
        ],
      },
      // Lebanon
      {
        country: "Lebanon",
        code: "LB",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://spartanarabia.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "fa23e2af-11da-42c0-aa2a-cca626564285",
          },
        ],
      },
      // Jordan
      {
        country: "Jordan",
        code: "JO",
        platform: "S1",
        languages: [
          {
            language: "english",
            code: "en",
            homeUrl: "https://spartanarabia.com/en",
            findRaceUrl: "/race/find-race",
            leaderboardUrl: "/race/past-results/[raceID]",
            ZDServiceKey: "fa23e2af-11da-42c0-aa2a-cca626564285",
          },
        ],
      },
    ],
  },
];
