import Vue from "vue";
import VueRouter from "vue-router";

import config from "@/config";
import { getCookie } from "@/cookie";
import { EVENT_SHARE } from "@/lists/images";

Vue.use(VueRouter);
function authGuard(to, from, next) {
  if (getCookie("user")) return next({ name: "home", query: to.query });
  return next();
}

const routes = [
  {
    path: "/",
    component: () => import("../views/Auth/AuthWrapper.vue"),
    beforeEnter: (to, from, next) => {
      if (getCookie("user")) return next();
      // redirect to login form
      if (to.name === "checkout")
        return next({
          name: "formLogin",
          query: { redirect: window.location.href, ...to.query },
        });
      // redirect to sign in form
      return next({
        name: "formSignIn",
        query: { redirect: window.location.href, ...to.query },
      });
    },
    children: [
      {
        path: "",
        component: () => import("../views/Profile.vue"),
        redirect: "/home",
        children: [
          {
            path: "events",
            name: "events",
            component: () => import("../views/EventsList.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "events/:id",
            name: "eventPage",
            component: () => import("../views/EventPage/EventView.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
            beforeEnter(to, from, next) {
              Object.keys(EVENT_SHARE).map((key) => {
                const img = new Image();
                img.src = EVENT_SHARE[key];
              });
              next();
            },
            // redirect: "/events",
          },
          {
            path: "events/:id/tickets/:barcode/:type",
            name: "tickets",
            component: () => import("../views/EventPage/TicketEditView.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "results",
            name: "results",
            component: () => import("../views/Results.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "/results/:eventId?/:id",
            name: "resultPage",
            component: () => import("../views/ResultPage/ResultView.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "tropaion",
            name: "tropaion",
            redirect: config.showTropaion ? "" : { name: "results" },
            component: () => import("../views/TropaionPage/Tropaion.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "tropaion/challenges",
            name: "challenges",
            redirect: config.showTropaion ? "" : { name: "results" },
            component: () => import("../views/TropaionPage/Challenges.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "teams",
            name: "teamsList",
            component: () => import("../views/Teams/TeamsListView.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "teams/create",
            name: "teamCreate",
            component: () => import("../views/Teams/CreateTeamView.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "teams/:id",
            component: () =>
              import(
                /* webpackChunkName: "teams" */ "../views/Teams/TeamViewWrapper.vue"
              ),
            children: [
              {
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "teams" */ "../views/Teams/TeamPage/TeamView.vue"
                  ),
                children: [
                  {
                    path: "",
                    name: "teamLeaderboard",
                    component: () =>
                      import(
                        /* webpackChunkName: "teams" */ "../views/Teams/TeamPage/TeamLeaderboard.vue"
                      ),
                    children: [],
                    meta: {
                      requiredAuth: true,
                      hasNavigationTabs: true,
                    },
                  },
                  {
                    path: "events",
                    name: "teamEvents",
                    component: () =>
                      import(
                        /* webpackChunkName: "teams" */ "../views/Teams/TeamPage/TeamEvents.vue"
                      ),
                    meta: {
                      requiredAuth: true,
                      hasNavigationTabs: true,
                    },
                  },
                ],
              },
              {
                path: "edit",
                name: "teamEdit",
                component: () =>
                  import(
                    /* webpackChunkName: "teams" */ "../views/Teams/EditTeamView.vue"
                  ),
                meta: {
                  requiredAuth: true,
                },
              },
            ],
          },
          {
            path: "home",
            name: "home",
            component: () => import("@/views/FeedPage/FeedView.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "benefits",
            name: "benefits",
            component: () => import("../views/Benefits/BenefitsView.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "benefits/unbreakable_pass",
            name: "UnbreakablePassPage",
            component: () =>
              import("../views/Benefits/UnbreakablePassView.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
          {
            path: "benefits/spartan_plus",
            name: "SpartanPlusPage",
            component: () => import("../views/Benefits/SpartanPlusView.vue"),
            meta: {
              requiredAuth: true,
              hasNavigationTabs: true,
            },
          },
        ],
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("../views/Settings.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "/subscription",
        name: "subscription",
        component: () => import("../views/SubscribePage/SubscribeView.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "/magazine/:id",
        name: "magazine",
        component: () => import("../views/DocumentReader.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "/reading/:doc",
        name: "reading",
        component: () => import("../views/DocumentReader.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "/events/:id/show-tickets",
        name: "eventTickets",
        component: () => import("../views/EventPage/TicketsListView.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "/event-invite",
        name: "inviteTeamToEvent",
        component: () => import("../views/Public/InviteTeamToEvent"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "/join-team/:inviteCode",
        alias: "/team_invite/:inviteCode",
        name: "joinTeam",
        component: () => import("../views/Teams/JoinTeamView.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "/:region/checkout",
        name: "checkout",
        component: () => import("../views/Checkout/CheckoutView.vue"),
        meta: {
          requiredAuth: true,
        },
      },
    ],
  },
  {
    path: "*",
    redirect: "/home",
  },
  // -----Auth-----
  {
    path: "/login",
    name: "formLogin",
    beforeEnter: authGuard,
    // beforeEnter: (to, from, next) => {
    //   if (getCookie("user")) return next({ name: "home" });
    //   return next();
    // },
    component: () => import("../views/Auth/FormLogin.vue"),
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/auth/conn",
    name: "oAuthAuthorizer",
    component: () => import("../views/Auth/oAuthAuthorizer.vue"),
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/sign-in",
    name: "formSignIn",
    beforeEnter: authGuard,
    component: () => import("../views/Auth/FormSignIn.vue"),
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/sign-up",
    name: "formSignUp",
    beforeEnter: authGuard,
    component: () => import("../views/Auth/FormSignUp.vue"),
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/sign-up-fb",
    name: "formSignUpFacebook",
    // beforeEnter: authGuard,
    component: () => import("../views/Auth/FormSignUpFacebook.vue"),
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/sign-up-apple",
    name: "formSignUpApple",
    component: () => import("../views/Auth/FormSignUpApple.vue"),
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: "/request-password-reset",
    name: "formRequestPasswordReset",
    component: () => import("../views/Auth/FormRequestPasswordReset.vue"),
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/reset-password",
    name: "formResetPassword",
    component: () => import("../views/Auth/FormResetPassword.vue"),
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/password-reset-email-sent",
    name: "resetEmailSend",
    component: () => import("../views/Auth/FormResetPasswordCode.vue"),
    // props: { view: "reset-email-send" },
    props: true,
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/reset-password-success",
    name: "resetPasswordSuccess",
    component: () => import("../views/Auth/FormInfoView.vue"),
    props: { view: "reset-password-success" },
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/password-reset-link-expired",
    name: "resetPasswordLinkExpired",
    component: () => import("../views/Auth/FormInfoView.vue"),
    props: { view: "reset-password-link-expired" },
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/confirmation-expired",
    name: "confirmationExpired",
    component: () => import("../views/Auth/FormInfoView.vue"),
    props: { view: "confirmation-link-expired" },
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: "/webview-test",
    name: "webViewTest",
    component: () => import("../views/WebViewTest.vue"),
    meta: {
      requiredAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from) {
    const navHeight = 107;
    // const tabsHeight = 42;
    const activeChallengesHeight = 236;
    // const scrollPos = 248;
    // console.log(navHeight, tabsHeight)
    const exceptionRoutes = ["teamLeaderboard", "teamEvents"];
    let isMobile = !!document.getElementById("mobile-navigation");

    if (
      exceptionRoutes.includes(to.name) &&
      exceptionRoutes.includes(from.name)
    )
      return;

    if (to.meta?.hasNavigationTabs && from.meta?.hasNavigationTabs) {
      // scroll on top
      if (!isMobile) return { x: 0, y: 0 };

      // feed exception
      if (to.name === "home")
        return { x: 0, y: navHeight + activeChallengesHeight };

      // save scroll position
      if (window.scrollY < navHeight) return;

      // scroll to mobile navigation tabs
      return {
        x: 0,
        // y: 288 - 42
        y: navHeight,
      };
    }

    // checkout exception
    if (to.name === "checkout") return { x: 0, y: 0 };
  },
});

export default router;
