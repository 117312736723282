<template>
  <auth-layout v-if="showForm">
    <template v-slot:title>
      <span>{{ $t("update_password_form_title") }}</span>
    </template>
    <template v-slot:subtitle>
      <span>{{ $t("update_password_form_subtitle") }}</span>
    </template>
    <template v-slot:form>
      <v-form ref="form" lazy-validation @submit.prevent="update">
        <div class="user-password">
          <v-text-field
            class="form-input"
            v-model="password"
            :label="$t('new_pass')"
            :type="showPassword ? 'text' : 'password'"
            @focus.stop="onPasswordFocus"
            @focusout="onPasswordBlur"
            id="new-password"
            data-testid="new-password"
          >
            <template v-slot:append>
              <div class="password-icon" @click="showPassword = !showPassword">
                <span class="material-icons" v-if="showPassword">
                  visibility
                </span>
                <span class="material-icons" v-if="!showPassword">
                  visibility_off
                </span>
              </div>
            </template>
          </v-text-field>
          <div class="password-hint" v-if="showPasswordHint">
            <span class="material-icons" v-if="password.length >= 8">
              done
            </span>
            <span class="hint-text">{{ $t("auth_form_password_hint") }}</span>
          </div>
        </div>
        <v-text-field
          class="form-input"
          v-model="passwordConfirm"
          :label="$t('confirm_new_password')"
          :type="showPasswordConfirm ? 'text' : 'password'"
          :rules="rules"
          @focus.stop="rules = []"
          id="confirm-new-password"
          data-testid="confirm-new-password"
        >
          <template v-slot:append>
            <div
              class="password-icon"
              @click="showPasswordConfirm = !showPasswordConfirm"
            >
              <span class="material-icons" v-if="showPasswordConfirm">
                visibility
              </span>
              <span class="material-icons" v-if="!showPasswordConfirm">
                visibility_off
              </span>
            </div>
          </template>
        </v-text-field>
        <div class="form-buttons">
          <mem-button
            type="submit"
            :btnType="'secondary-dark'"
            :disabled="disableBtn"
            :loading="loading"
            >{{ $t("update") }}</mem-button
          >
        </div>
      </v-form>
    </template>
  </auth-layout>
</template>
<script>
import mixinAuthForms from "./mixinAuthForms";
import { mapActions } from "vuex";
export default {
  inheritAttrs: true,
  data: () => ({
    showForm: true,
    password: "",
    passwordConfirm: "",
    showPassword: false,
    showPasswordConfirm: false,
    showPasswordHint: false,
    rules: [],
    loading: false,
  }),
  mixins: [mixinAuthForms],
  components: {
    "auth-layout": () => import("./AuthLayout.vue"),
    "mem-button": () => import("../../components/base/BaseButton.vue"),
  },
  computed: {
    disableBtn() {
      return (
        !this.password || !this.passwordConfirm || this.password.length < 8
      );
    },
    // resetToken() {
    //   return this.$route.query?.token;
    // },
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      let { email, code } = vm.$route.query;
      if (!email || !code)
        vm.$router.push({ name: "formSignIn", query: vm.$route.query });
      // try {
      //   await vm.checkPasswordResetToken(vm.resetToken);
      //   vm.showForm = true;
      // } catch (err) {
      //   console.log(err);
      //   next({ name: "resetPasswordLinkExpired" });
      // }
    });
  },
  methods: {
    ...mapActions([
      "updatePassword",
      "checkPasswordResetToken",
      "changePasswordWithCode",
    ]),
    // async update() {
    //   let formValid = this.validateForm();
    //   if (!formValid) return;
    //   this.loading = true;
    //   try {
    //     const redirectLink = await this.updatePassword({
    //       password: this.password,
    //       resetToken: this.resetToken,
    //       redirect: this.redirectUrl,
    //     });
    //     // this.$router.push({ name: "resetPasswordSuccess" });
    //     window.location = redirectLink;
    //   } catch (error) {
    //     console.log("Update password error", error);
    //     this.loading = false;
    //     if (error.multipass) this.$router.push({ name: "home" });
    //   }
    // },
    async update() {
      let formValid = this.validateForm();
      if (!formValid) return;
      this.loading = true;
      try {
        let { email, code } = this.$route.query;
        let redirectLink = await this.changePasswordWithCode({
          email,
          code,
          password: this.password,
          redirect: this.redirectUrl,
        });
        this.loading = false;
        // add redirect url support
        window.location = redirectLink;
      } catch (error) {
        this.loading = false;
        console.log("Update passoword error", error);
        if (error.multipass) this.$router.push({ name: "home" });
      }
    },
    validateForm() {
      // why is this works ?
      this.rules.push((val) => {
        if (val !== this.password) return this.$t("passwords_match");
        return true;
      });
      return this.$refs.form.validate();
    },
    onPasswordFocus() {
      this.showPasswordHint = true;
    },
    onPasswordBlur() {
      this.showPasswordHint = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$icon-color: rgba(255, 255, 255, 0.4);
.form-input {
  .material-icons {
    color: $icon-color;
    margin-bottom: 6px;
    @include cursorPointer;
  }
}
.user-password {
  display: flex;
  position: relative;
  .password-hint {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 20px;
    .hint-text {
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      opacity: 0.4;
      line-height: 14px;
    }
    .material-icons {
      color: #64dd17;
      font-size: 18px;
      margin-right: 7px;
      line-height: 14px;
    }
  }
}
.form-buttons {
  margin-top: 40px;
  @media screen and (max-width: 499px) {
    margin-top: 0;
  }
}
</style>