<template>
  <div class="he-promo-card">
    <div class="card-content">
      <img src="@/assets/HE_logo.svg" alt="Hotel Engine" class="card-logo" />
      <div class="card-title">{{ $t("he_promo_card_title") }}</div>
      <div class="card-text">{{ $t("he_promo_card_text") }}</div>
    </div>
    <mem-button
      class="hotel-engine-banner-button"
      btn-type="secondary-dark"
      @click="openLink"
      >{{ $t("go") }}</mem-button
    >
  </div>
</template>
<script>
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  methods: {
    openLink() {
      let windowReference = window.open();
      windowReference.location =
        "https://members.engine.com/join/DAD69B408E0C0FA1";
    },
  },
};
</script>
<style lang="scss" scoped>
.he-promo-card {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "content button";
  padding: 0 24px;
  height: 160px;
  overflow: hidden;
  background-image: url("https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/hotel_engine_banner_background.png");
  background-size: cover;
  @media screen and (max-width: $mobile) {
    height: 176px;
    background-image: url("https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/hotel_engine_banner_background_mobile.png");
  }
  .card-content {
    grid-area: content;
    align-self: center;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .card-logo {
      height: 22px;
      @media screen and (max-width: $mobile) {
        height: 20px;
      }
    }
    .card-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
      margin-top: 14px;
      margin-bottom: 14px;
      @media screen and (max-width: $mobile) {
        font-size: 18px;
        max-width: 200px;
        margin-bottom: 8px;
      }
    }
    .card-text {
      font-size: 14px;
      line-height: 17px;
      max-width: 256px;
      @media screen and (max-width: $mobile) {
        max-width: 186px;
      }
    }
  }

  button {
    grid-area: button;
    align-self: center;
    height: 36px;
    padding: 0 28px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.04em;
    @media screen and (max-width: $mobile) {
      margin-bottom: 26px;
      align-self: flex-end;
    }
  }
}
</style>