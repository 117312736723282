<template>
  <div class="code-view">
    <auth-layout>
      <template v-slot:image>
        <img src="@/assets/msg_icon.svg" alt="msg" />
      </template>
      <template v-slot:title>
        <span>{{ $t("auth_form_code_title") }}</span>
      </template>
      <template v-slot:subtitle>
        <i18n tag="div" path="auth_form_code_subtitle">
          <span class="email-text">{{ $route.query?.email }}</span>
        </i18n>
      </template>
      <template v-slot:form>
        <v-form ref="requestCode" class="code-form">
          <v-otp-input
            ref="otpInput"
            v-model="code"
            length="4"
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            :color="hasError ? 'error' : 'primary'"
            :disabled="loading || codeBanned || hideTextSide"
            @input.native="onInput($event)"
            @finish="applyCode"
          ></v-otp-input>
        </v-form>
      </template>
      <template v-slot:footer>
        <!-- text/errors side -->
        <template v-if="!hideTextSide">
          <span v-if="codeIncorrect" class="footer-error-text">
            {{ $t("auth_form_code_incorrect_error") }}
          </span>
          <span v-else-if="codeExpired" class="footer-error-text">
            {{ $t("auth_form_code_expired_error") }}
          </span>
          <span v-else-if="codeBanned" class="footer-error-text">
            {{ banTimerText }}
          </span>
          <span v-else class="footer-text">
            {{ $t("auth_form_code_footer") }}
          </span>
        </template>
        <!-- link buttons side -->
        <template v-if="!codeBanned">
          <span v-if="requestTimer !== 0" class="footer-link">
            {{ requestTimerText }}
          </span>
          <span
            v-else-if="codeExpired"
            class="footer-link"
            @click="requestCode"
          >
            {{ $t("auth_form_code_footer_link2") }}
          </span>
          <span v-else class="footer-link" @click="requestCode">
            {{ $t("auth_form_code_footer_link") }}
          </span>
        </template>
      </template>
    </auth-layout>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {
    "auth-layout": () => import("./AuthLayout.vue"),
  },
  data: () => ({
    otpInput: "",

    code: null,
    loading: false,
    hideTextSide: false,

    intervalId: null,
    requestTimer: 0,
    banIntervalId: null,
    banTimer: 300,

    // errors
    codeIncorrect: false,
    codeExpired: false,
    codeBanned: false,
  }),
  computed: {
    requestTimerText() {
      let minutes = ~~(this.requestTimer / 60); // ~~ is a shorthand for Math.floor
      let seconds = this.requestTimer - minutes * 60;

      let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      let formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

      return this.$t("auth_form_code_link_cd", {
        0: `${formattedMinutes}:${formattedSeconds}`,
      });
    },
    banTimerText() {
      let minutes = ~~(this.banTimer / 60); // ~~ is a shorthand for Math.floor
      let seconds = this.banTimer - minutes * 60;

      let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      let formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

      return this.$t("auth_form_code_banned_error", {
        0: `${formattedMinutes}:${formattedSeconds}`,
      });
    },
    hasError() {
      return this.codeIncorrect || this.codeExpired || this.codeBanned;
    },
  },
  watch: {
    banTimer: {
      handler(val) {
        if (val > 0) return;
        this.hideTextSide = true;
        this.codeBanned = false;
        // if (oldVal > 0 && newVal === 0) {
        //   this.codeBanned = false;
        // }
      },
    },
  },
  methods: {
    ...mapActions(["validatePasswordCode", "requestPassworResetCode"]),
    // Safari issue fix
    onInput(event) {
      const value = event.target.value.replace(/\D/g, "");
      event.target.value = value;
    },
    startTimer() {
      if (this.intervalId) clearInterval(this.intervalId);

      this.intervalId = setInterval(() => {
        if (this.requestTimer === 0) return clearInterval(this.intervalId);
        this.requestTimer--;
      }, 1000);
    },
    startBanTimer() {
      this.banIntervalId = setInterval(() => {
        this.banTimer--;
        if (this.banTimer === 0) clearInterval(this.banIntervalId);
      }, 1000);
    },
    async requestCode() {
      this.code = null;
      this.loading = true;
      this.hideTextSide = false;
      this.codeIncorrect = false;
      this.codeExpired = false;
      try {
        await this.requestPassworResetCode(this.$route.query?.email);
        this.loading = false;
        this.requestTimer = 60;
        this.startTimer();
      } catch (error) {
        // Add timer error
        // reset_password_code_locked
        this.loading = false;
        console.log("Request code error", error);
        if (error.error === "reset_password_code_locked") {
          this.codeBanned = true;
          this.banTimer = error.retry_time || 0;
          this.startBanTimer();
          return;
        }
        if (error.retry_time) {
          this.requestTimer = error.retry_time;
          this.startTimer();
        }
      }
    },
    async applyCode() {
      console.log("Apply code");

      this.loading = true;
      //   this.hideTextSide = false;
      this.codeIncorrect = false;
      this.codeExpired = false;

      let { email } = this.$route.query;

      try {
        await this.validatePasswordCode({ email, code: this.code });
        this.$router.push({
          name: "formResetPassword",
          query: { ...this.$route.query, email, code: this.code },
        });
      } catch (error) {
        this.loading = false;
        this.code = "";
        console.log("Apply code error", error);

        if (error.error === "reset_password_code_locked") {
          this.codeBanned = true;
          this.banTimer = error.retry_time || 300;
          this.startBanTimer();
        } else if (error.error === "code_has_expired") {
          this.codeExpired = true;
        } else {
          this.codeIncorrect = true;
        }
        //
        this.$nextTick(() => {
          const otpInput = this.$refs.otpInput;
          if (otpInput) {
            const firstInput = otpInput.$el.querySelector("input");
            if (firstInput) {
              firstInput.focus();
            }
          }
        });
      }
    },
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$route.params?.error === "reset_password_code_locked") {
        vm.codeBanned = true;
        vm.banTimer = vm.$route.params?.retry_time || 0;
        vm.startBanTimer();
      }
      // requestTimer
      if (vm.$route.params?.timer) {
        vm.requestTimer = vm.$route.params?.timer;
        vm.startTimer();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.code-view {
  ::v-deep(.auth-layout) {
    .component-aria {
      .auth-form {
        max-width: 416px;
        .form-wrap {
          .form-image {
            margin-bottom: 20px;
            img {
              height: 36px;
            }
          }
          .form-text-fields {
            margin-top: 28px;
          }
          .form-footer {
            min-height: 38px;
            margin-top: 36px;
          }
        }
      }
    }
  }
}

.email-text {
  opacity: 0.6;
}
.code-form {
  max-width: 230px;
  .v-otp-input {
    ::v-deep .v-input input {
      font-size: 36px;
      font-weight: 500;
      max-height: 100%;
      caret-color: #ffffff !important;
    }
    ::v-deep .v-input__slot {
      min-height: 60px;
    }
  }
}
.footer-text {
  color: #ffffff;
  + .footer-link {
    margin-left: 4px;
  }
}
.footer-error-text {
  color: #cf1019;
}
.footer-link {
  text-decoration: underline;
  color: #ffffff;
  opacity: 0.6;
  @include cursorPointer;
}
</style>