import config from "@/config";
import { pushDataLayerEvent } from "@/common/helpers";
import { TICKETS_COOKIE_NAME, setCookie, getCookie } from "@/cookie";

const API = config.apiGate;
const API_AUTH = config.apiAuth;
const F5_HEADER_NAME = "Imp-Apg";

function getF5Cookie() {
  let f5CookieString = getCookie("_imp_apg_r_");
  if (!f5CookieString) return;

  let f5CookieParsed = JSON.parse(f5CookieString);
  return f5CookieParsed.c;
}

export default {
  async signIn(ctx, { data, redirect }) {
    const authData = {
      athlete_user: data,
    };
    let headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    let cookieF5 = getF5Cookie();
    if (cookieF5) headers.append(F5_HEADER_NAME, cookieF5);

    const res = await fetch(`${API_AUTH}/athlete_users/sign_in`, {
      method: "POST",
      headers,
      body: JSON.stringify(authData),
    });

    if (!res.ok) {
      if (res.status === 401)
        return Promise.reject({
          invalid_credentials: true,
          error: await res.json(),
        });
      else return Promise.reject(`Server error. Status: ${res.status}`);
    }

    let token = res.headers.get("Authorization");
    if (!token) return Promise.reject("Auth error");

    let authResult = await res.json();
    // user cart
    let cartToken = getCookie(TICKETS_COOKIE_NAME);
    if (!cartToken && authResult?.cart_token)
      setCookie(TICKETS_COOKIE_NAME, authResult.cart_token, 30);

    ctx.commit("updateUser", authResult);
    ctx.commit("updatePic", authResult.avatar);
    setCookie("user", token);

    pushDataLayerEvent("loggedIn", {
      loginType: "Email",
      userData: authResult,
    });
    try {
      let shopifyRedirect = await ctx.dispatch("connectShopify", redirect);
      return shopifyRedirect;
    } catch (err) {
      return Promise.reject({ multipass: true, error: err });
    }
  },
  async connectShopify(ctx, redirect) {
    const data = {
      login_redirect: redirect,
    };
    const res = await fetch(`${API_AUTH}/athlete_users/multipass`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getCookie("user"),
      }),
      body: JSON.stringify(data),
    });
    if (!res.ok) return Promise.reject("Multipass error");
    let result = await res.text();
    return result;
  },
  async signUp(ctx, { data, redirect }) {
    const authData = {
      athlete_user: data,
    };
    let headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    let cookieF5 = getF5Cookie();
    if (cookieF5) headers.append(F5_HEADER_NAME, cookieF5);

    const res = await fetch(`${API_AUTH}/athlete_users`, {
      method: "POST",
      headers,
      body: JSON.stringify(authData),
    });

    if (!res.ok) {
      if (res.status === 422) return Promise.reject({ exist: true });
      else return Promise.reject(`Server error. Status: ${res.status}`);
    }

    let token = res.headers.get("authorization");
    if (!token) return Promise.reject("Auth error");

    setCookie("user", token);

    let authResult = await res.json();

    pushDataLayerEvent("accountCreated", {
      loginType: "Email",
      userData: authResult,
    });
    try {
      let shopifyRedirect = await ctx.dispatch("connectShopify", redirect);
      return shopifyRedirect;
    } catch (err) {
      return Promise.reject({ multipass: true, error: err });
    }
  },
  async checkUserExist(ctx, email) {
    const res = await fetch(`${API_AUTH}/athlete_users/exists`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ email }),
    });
    if (!res.ok) return Promise.reject("Check user error");
    let result = await res.json();
    return result;
  },
  // Deprecated(for now)
  async requestNewPassword(ctx, { email, redirectUrl, params }) {
    let { lang, country } = params;
    let queryParams = "";

    if (lang && country) queryParams = `?lang=${lang}&country=${country}`;
    if (lang && !country) queryParams = `?lang=${lang}`;
    if (!lang && country) queryParams = `?country=${country}`;

    const data = {
      athlete_user: { email },
      password_reset_form_url: redirectUrl,
    };
    const res = await fetch(
      `${API_AUTH}/athlete_users/password${queryParams}`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) return Promise.reject({ status: res.status });
    // const result = await res.json();
    // console.log(result, "requestNewPassword result");
  },
  async requestPassworResetCode(ctx, email) {
    const data = {
      athlete_user: { email },
    };
    const res = await fetch(`${API}/auth/athlete_users/password_code`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      const result = await res.json();
      return Promise.reject({ status: res.status, ...result });
    }
  },
  async validatePasswordCode(ctx, { email, code }) {
    const payload = {
      athlete_user: { email, reset_password_code: code },
    };

    const res = await fetch(`${API}/auth/athlete_users/password_with_code`, {
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload),
    });

    if (!res.ok) {
      const result = await res.json();
      return Promise.reject({ status: res.status, ...result });
    }
  },
  async changePasswordWithCode(ctx, { email, code, password, redirect }) {
    const payload = {
      athlete_user: { email, reset_password_code: code, password },
    };
    let headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    let cookieF5 = getF5Cookie();
    if (cookieF5) headers.append(F5_HEADER_NAME, cookieF5);

    const res = await fetch(`${API}/auth/athlete_users/password_with_code`, {
      method: "PUT",
      headers,
      body: JSON.stringify(payload),
    });

    if (!res.ok) {
      const result = await res.json();
      return Promise.reject({ status: res.status, error: result?.error });
    }

    // User log in
    let token = res.headers.get("Authorization");
    if (!token) return Promise.reject("Auth error: token is missing");

    setCookie("user", token);
    pushDataLayerEvent("passResetSuccess");

    try {
      let shopifyRedirect = await ctx.dispatch("connectShopify", redirect);
      return shopifyRedirect;
    } catch (err) {
      return Promise.reject({ multipass: true, error: err });
    }
  },

  async updatePassword(ctx, { password, resetToken, redirect }) {
    // updateToken receiving from /reset-password?password_reset_token=token
    const data = {
      athlete_user: { password, reset_password_token: resetToken },
    };
    let headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    let cookieF5 = getF5Cookie();
    if (cookieF5) headers.append(F5_HEADER_NAME, cookieF5);

    const res = await fetch(`${API_AUTH}/athlete_users/password`, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    });
    if (!res.ok)
      return Promise.reject(`Update password error. Code: ${res.status}`);

    let token = res.headers.get("Authorization");
    if (!token) return Promise.reject("Auth error: token is missing");

    setCookie("user", token);
    pushDataLayerEvent("passResetSuccess");

    try {
      let shopifyRedirect = await ctx.dispatch("connectShopify", redirect);
      return shopifyRedirect;
    } catch (err) {
      return Promise.reject({ multipass: true, error: err });
    }
  },
  async checkPasswordResetToken(ctx, token) {
    const res = await fetch(
      `${API_AUTH}/athlete_users/password/validate_token?token=${token}`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      }
    );
    if (!res.ok) return Promise.reject("Check reset password token error");
    let result = await res.json();
    if (!result.valid) return Promise.reject("Reset password token expired");
    console.log(result, "check result");
  },
  async socialSignUp(ctx, { data, redirect, oauthToken }) {
    const authData = {
      athlete_user: data,
      oauth_token: oauthToken,
    };

    let headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    let cookieF5 = getF5Cookie();
    if (cookieF5) headers.append(F5_HEADER_NAME, cookieF5);

    const res = await fetch(`${API}/auth/athlete_users/social_sign_up`, {
      method: "POST",
      headers,
      body: JSON.stringify(authData),
    });

    if (!res.ok) {
      if (res.status === 422) return Promise.reject({ exist: true });
      else return Promise.reject(`Server error. Status: ${res.status}`);
    }

    let token = res.headers.get("authorization");
    if (!token) return Promise.reject("Auth error: token is missing");

    setCookie("user", token);

    let authResult = await res.json();

    pushDataLayerEvent("accountCreated", {
      loginType: "Facebook",
      userData: authResult,
    });
    try {
      let shopifyRedirect = await ctx.dispatch("connectShopify", redirect);
      return shopifyRedirect;
    } catch (err) {
      return Promise.reject({ multipass: true, error: err });
    }
  },
  async authorizeOAuth(ctx, { token, redirect, authType, newUser }) {
    if (!token) return Promise.reject("Auth error");

    setCookie("user", `Bearer ${token}`);

    await ctx.dispatch("getUserData");

    let dataLayerEvent = "loggedIn";
    if (newUser === "true") dataLayerEvent = "accountCreated";

    pushDataLayerEvent(dataLayerEvent, {
      loginType: authType,
      userData: ctx.rootState?.profile?.user,
    });

    try {
      const shopifyRedirect = await ctx.dispatch("connectShopify", redirect);
      return Promise.resolve(shopifyRedirect);
    } catch (err) {
      return Promise.reject({ multipass: true, error: err });
    }
  },
};
