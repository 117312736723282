<template>
  <div class="info-view">
    <template v-if="view === 'reset-email-send'">
      <auth-layout>
        <template v-slot:image>
          <img src="./../../assets/msg_icon.svg" alt="msg" />
        </template>
        <template v-slot:title>
          <span>{{ $t("check_your_inbox") }}</span>
        </template>
        <template v-slot:subtitle>
          <span
            >{{ $t("reset_email_send_part_1") }}
            <span class="email-text">{{ email }}</span>
            {{ $t("reset_email_send_part_2") }}</span
          >
        </template>
      </auth-layout>
    </template>
    <!-- deprecated -->
    <template v-if="view === 'reset-password-success'">
      <auth-layout>
        <template v-slot:image>
          <img src="./../../assets/icon_done.svg" alt="done" />
        </template>
        <template v-slot:title>
          <span>{{ $t("password_updated") }}</span>
        </template>
        <template v-slot:subtitle>
          <span>{{ $t("you_can_sign_in") }}</span>
        </template>
        <template v-slot:buttons>
          <mem-button
            :btnType="'secondary-dark'"
            @click="backTo('formSignIn')"
            >{{ $t("back_to_login") }}</mem-button
          >
        </template>
      </auth-layout>
    </template>
    <template v-if="view === 'reset-password-link-expired'">
      <auth-layout>
        <template v-slot:image>
          <img src="./../../assets/time_is_up.svg" alt="ops!" />
        </template>
        <template v-slot:title>
          <!-- <span>{{ $t("password_link_expired") }}</span> -->
          <span>{{ $t("this_link") }}</span>
          <span>{{ $t("has_expired") }}</span>
        </template>
        <template v-slot:subtitle>
          <span>{{ $t("try_request_again") }}</span>
        </template>
        <template v-slot:buttons>
          <mem-button
            :btnType="'secondary-dark'"
            @click="backTo('formLogin')"
            >{{ $t("back_to_login") }}</mem-button
          >
        </template>
      </auth-layout>
    </template>
    <template v-if="view === 'confirmation-link-expired'">
      <auth-layout>
        <template v-slot:image>
          <img src="./../../assets/time_is_up.svg" alt="ops!" />
        </template>
        <template v-slot:title>
          <span>{{ $t("this_link") }}</span>
          <span>{{ $t("has_expired") }}</span>
        </template>
        <template v-slot:subtitle>
          <span>{{ $t("confirmation_expired_subtitle") }}</span>
        </template>
        <template v-slot:buttons>
          <mem-button
            :btnType="'secondary-dark'"
            @click="backTo('formLogin')"
            >{{ $t("back_to_login") }}</mem-button
          >
        </template>
      </auth-layout>
    </template>
  </div>
</template>
<script>
export default {
  data: () => ({
    // email: "",
  }),
  components: {
    "auth-layout": () => import("./AuthLayout.vue"),
    "mem-button": () => import("../../components/base/BaseButton.vue"),
  },
  //   view prop receiving from router
  props: ["view"],
  computed: {
    email() {
      return this.$route.query?.email;
    },
  },
  methods: {
    backTo(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>
<style lang="scss" scoped>
.info-view {
  height: 100%;
  ::v-deep(.auth-layout) {
    .component-aria {
      .auth-form {
        align-items: center;
        max-width: 480px;
        .form-wrap {
          .form-text {
            max-width: 416px;
          }
        }
      }
    }
  }
  .email-text {
    opacity: 0.6;
  }
  button {
    margin-top: 62px;
    min-width: 154px;
    @media screen and (max-width: 499px) {
      margin-top: 44px;
      width: 100%;
    }
  }
}
</style>